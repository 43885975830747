import map from '@/assets/map.webp'
import { Link } from 'react-router-dom'
import { useState } from 'react'

const k = 'Zm1sNG5hMjBud3ZueW43cGlnNTBra2U2'
function makeid(length: number) {
    let result = ''
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
        counter += 1
    }
    return result
}
async function hash(s: string) {
    const encoder = new TextEncoder()
    const raw = encoder.encode(s)
    const hashBuffer = await crypto.subtle.digest('SHA-256', raw)
    const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
    // convert bytes to hex string
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
}
export const Contact = () => {
    const [name, setName] = useState('')
    const [rem, setRem] = useState('')
    const [subject, setSubject] = useState('')
    const [msg, setMsg] = useState('')
    const [state, setState] = useState<'success' | 'error' | null>(null)
    const [disabled, setDisabled] = useState(true)
    const verifyAndSend = () => {
        // Code to verify and send the form data
        setDisabled(true)
        //// n, ea, eav, sj, msg
        setTimeout(() => {
            const data = {
                n: name,
                ea: rem,
                eav: (document.getElementById('c_rem_2') as HTMLInputElement).value,
                sj: subject,
                msg: msg
            }
            atob(k)
            const up = makeid(12) // 'wugso0dtrcod';//

            hash(atob(k) + '/_proc/' + up + JSON.stringify(data)).then(h => {
                const digest = h.toString()
                console.log(digest)
                fetch('/_proc/' + up, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'X-Validation': digest,
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                    .then(res => res.json())
                    .then(json => {
                        if (json.result) {
                            setState('success')
                        } else {
                            setState('error')
                        }
                    })
            })
        }, 0)
    }
    const reload = (state: 'success' | 'error' | null) => {
        if (state === 'success') {
            setName('')
            setRem('')
            setSubject('')
            setMsg('')
            setDisabled(true)
        } else {
            validateForm()
        }
        setState(null)
    }

    const validateForm = () => {
        let disabled = false

        if (name.length == 0) disabled = true
        if (rem.length == 0) disabled = true
        if (subject.length == 0) disabled = true
        if (msg.length == 0) disabled = true
        setDisabled(disabled)
    }
    return (
        <>
            <div className='pt-10 flex-grow flex items-center'>
                <div className='max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                    <div className='text-center'>
                        <h1 className=' font-bold tracking-tight text-gray-900 text-4xl sm:text-5xl lg:text-6xl'>
                            Kontaktieren <span className='text-[#826E42]'>Sie uns.</span>
                        </h1>
                    </div>
                </div>
            </div>
            <main id='content' className='pb-10 flex-grow flex'>
                <div className='max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                    <div className='grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center'>
                        <div className='p-4 mx-auto max-w-xl bg-white h-full w-full'>
                            <div className='mt-4 mb-8'>
                                <ul className='mb-6 md:mb-0'>
                                    <li className='flex h-12 row items-center'>
                                        <div className='flex h-10 w-10 items-center justify-center rounded bg-gray-800 text-gray-50'>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                fill='currentColor'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className='h-6 w-6'
                                                viewBox='30 31.5 452 452'>
                                                <g fillRule='evenodd'>
                                                    <path d='M 441,81 A 40,40 0 0 1 481,121 L 481,395 A 40,40 0 0 1 441,435 L 71,435 A 40,40 0 0 1 31,395 L 31,121 A 40,40 0 0 1 71,81 Z M 63,121 A 8,8 0 0 1 71,113 L 441,113 A 8,8 0 0 1 449,121 L 449,131 C 448,150 438,166 417,188 L 288,291 C258,311 254,311 224,291 L 95,188 C 74,166 64,150 63,131 Z M 63,202 C 66,205 75,214 80,218 L 208,320 C 245,344 265,344 304,320 L 432,218 C 437,214 446,205 449,202 L 449,394 A 8,8 0 0 1 441,402 L 71,402 A 8,8 0 0 1 63,394 Z' />
                                                </g>
                                            </svg>
                                        </div>
                                        <div className='ml-4'>
                                            <p className='text-gray-800'>E-Mail: info@majo-holding.de</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {state ? (
                                <>
                                    {state == 'success' ? (
                                        <>
                                            <div
                                                className='p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-100'
                                                role='alert'>
                                                <span className='font-medium'>Vielen Dank!</span>
                                                <br /> Ihre Nachricht wurde erfolgreich an uns übermittelt. Wir werden
                                                uns schnellstmöglich bei Ihnen melden.
                                            </div>
                                            <button
                                                type='button'
                                                className='text-white bg-gray-800 disabled:bg-gray-300 hover:bg-gray-600 tracking-wide rounded-md text-sm px-4 py-3 w-full'
                                                onClick={() => {
                                                    reload(state)
                                                }}>
                                                Formular neu laden
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                className='p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-100'
                                                role='alert'>
                                                <span className='font-medium'>Entschuldigung!</span>
                                                <br /> Beim Versand Ihrer Nachricht ist ein Fehler aufgetreten. Bitte
                                                versuchen Sie es zu einem späteren Zeitpunkt erneut. Alternativ können
                                                Sie uns gerne auch direkt per Email kontaktieren:
                                                <div className='flex row mt-2 items-center'>
                                                    <div className='flex h-10 w-10 items-center justify-center '>
                                                        <svg
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            fill='rgb(31 41 55 / var(--tw-text-opacity))'
                                                            stroke='rgb(31 41 55 / var(--tw-text-opacity))'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            className='h-6 w-6'
                                                            viewBox='30 31.5 452 452'>
                                                            <g fillRule='evenodd'>
                                                                <path d='M 441,81 A 40,40 0 0 1 481,121 L 481,395 A 40,40 0 0 1 441,435 L 71,435 A 40,40 0 0 1 31,395 L 31,121 A 40,40 0 0 1 71,81 Z M 63,121 A 8,8 0 0 1 71,113 L 441,113 A 8,8 0 0 1 449,121 L 449,131 C 448,150 438,166 417,188 L 288,291 C258,311 254,311 224,291 L 95,188 C 74,166 64,150 63,131 Z M 63,202 C 66,205 75,214 80,218 L 208,320 C 245,344 265,344 304,320 L 432,218 C 437,214 446,205 449,202 L 449,394 A 8,8 0 0 1 441,402 L 71,402 A 8,8 0 0 1 63,394 Z' />
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div className='ml-4'>
                                                        <a
                                                            className='text-gray-800 hover:underline'
                                                            href='mailto:info@majo-holding.de'>
                                                            info@majo-holding.de
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                type='button'
                                                className='text-white bg-gray-800 disabled:bg-gray-300 hover:bg-gray-600 tracking-wide rounded-md text-sm px-4 py-3 w-full'
                                                onClick={() => {
                                                    reload(state)
                                                }}>
                                                Formular neu laden
                                            </button>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <h1 className='text-3xl text-gray-800 bold'>Direktnachricht</h1>
                                    <form className='mt-4'>
                                        <label className='mb-0 pb-0 text-xs text-gray-800 ml-1' htmlFor='c_name'>
                                            Ihr Name (*)
                                        </label>
                                        <input
                                            type='text'
                                            id='c_name'
                                            name='c_name'
                                            value={name}
                                            onChange={e => {
                                                validateForm()
                                                setName(e.target.value)
                                            }}
                                            onBlur={() => {
                                                validateForm()
                                            }}
                                            placeholder='Bitte geben Sie Ihren Namen ein...'
                                            className='w-full mb-2 rounded-md py-3 px-4 text-gray-800 bg-gray-100 focus:bg-transparent text-sm outline-gray-800'
                                        />
                                        <input type='email' id='email' name='email' className='c_rem_s_fmt' />
                                        <label className='mb-0 pb-0 text-xs text-gray-800 ml-1' htmlFor='c_rem'>
                                            Ihre Emailadresse (*)
                                        </label>
                                        <input
                                            type='text'
                                            id='c_rem'
                                            name='c_rem'
                                            value={rem}
                                            onChange={e => {
                                                validateForm()
                                                setRem(e.target.value)
                                            }}
                                            onBlur={() => {
                                                validateForm()
                                            }}
                                            placeholder='Bitte geben Sie Ihre Email ein...'
                                            className='w-full mb-2 rounded-md py-3 px-4 text-gray-800 bg-gray-100 focus:bg-transparent text-sm outline-gray-800'
                                        />
                                        <label
                                            className='mb-0 pb-0 text-xs text-gray-800 ml-1 c_rem_s_fmt'
                                            htmlFor='c_rem_2'>
                                            Bitte wiederholen Sie Ihre Emailadresse (*)
                                        </label>
                                        <input
                                            type='text'
                                            id='c_rem_2'
                                            name='c_rem_2'
                                            placeholder='Bitte wiederholen Sie Ihre Email...'
                                            className='w-full mb-2 rounded-md py-3 px-4 text-gray-800 bg-gray-100 focus:bg-transparent text-sm outline-gray-800 c_rem_s_fmt'
                                        />

                                        <label className='mb-0 pb-0 text-xs text-gray-800 ml-1' htmlFor='c_name'>
                                            Ihr Anliegen (*)
                                        </label>

                                        <input
                                            type='text'
                                            id='c_subject'
                                            name='c_subject'
                                            value={subject}
                                            onChange={e => {
                                                validateForm()
                                                setSubject(e.target.value)
                                            }}
                                            onBlur={() => {
                                                validateForm()
                                            }}
                                            placeholder='Bitte den Betreff Ihres Anliegens ein...'
                                            className='w-full mb-2 rounded-md py-3 px-4 text-gray-800 bg-gray-100 focus:bg-transparent text-sm outline-gray-800'
                                        />
                                        <label className='mb-0 pb-0 text-xs text-gray-800 ml-1' htmlFor='c_name'>
                                            Ihre Nachricht (*)
                                        </label>

                                        <textarea
                                            placeholder='Ihre Nachricht an uns...'
                                            id='c_msg'
                                            name='c_msg'
                                            value={msg}
                                            onChange={e => {
                                                validateForm()
                                                setMsg(e.target.value)
                                            }}
                                            onBlur={() => {
                                                validateForm()
                                            }}
                                            rows={6}
                                            className='w-full mb-2 rounded-md px-4 text-gray-800 bg-gray-100 focus:bg-transparent text-sm pt-3 outline-gray-800'></textarea>
                                        <button
                                            disabled={disabled}
                                            type='button'
                                            className='text-white bg-gray-800 disabled:bg-gray-300 hover:bg-gray-600 tracking-wide rounded-md text-sm px-4 py-3 w-full'
                                            onClick={verifyAndSend}>
                                            Senden
                                        </button>
                                        <p className='text-gray-800 text-xs mt-2'>
                                            Die mit (*) markierten Felder sind Pflichtangaben.
                                        </p>
                                        <p className='text-gray-800 text-xs mt-2'>
                                            Indem Sie auf „Senden“ klicken, stimmen Sie zu, dass Ihre Angaben und Daten
                                            zur Beantwortung Ihrer Anfrage gespeichert und verarbeitet werden. Weitere
                                            Informationen finden Sie in unserer{' '}
                                            <Link
                                                className='underline cursor-pointer hover:text-[#826E42]'
                                                to='/data-privacy'>
                                                Datenschutzerklärung
                                            </Link>
                                            .
                                        </p>
                                    </form>
                                </>
                            )}
                        </div>
                        <div className={'p-4 mx-auto max-w-xl  h-full w-full'}>
                            <div className={'mt-4 mb-8'}>
                                <ul className='mb-6 md:mb-0'>
                                    <li className='flex h-12 row items-center'>
                                        <div className='flex h-10 w-10 items-center justify-center rounded bg-gray-800 text-gray-50'>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                stroke='currentColor'
                                                strokeWidth='2'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                className='h-6 w-6'>
                                                <path d='M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0'></path>
                                                <path d='M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z'></path>
                                            </svg>
                                        </div>
                                        <div className='ml-4'>
                                            <p className='text-gray-800'>Judengasse 23</p>
                                            <p className='text-gray-800'>61169 Friedberg, Deutschland</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <h1 className='text-3xl text-gray-800 bold mb-10'>Anfahrt</h1>
                            <div className='relative ms-4 mt-4'>
                                <img className='w-full rounded-md' src={map} alt='Managing the future.' />
                                <div className='absolute inset-0 -z-[1] bg-gradient-to-tr from-[#826E42] via-white/0 to-white/0 size-full rounded-md mt-4 -mb-4 me-4 -ms-4 lg:mt-6 lg:-mb-6 lg:me-6 lg:-ms-6'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export function Component() {
    return <Contact />
}

/*

    <li className="flex row mt-2 items-center">
        <div
            className="flex h-10 w-10 items-center justify-center rounded bg-gray-800 text-gray-50">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                className="h-6 w-6">
                                                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                                <path d="M12 7v5l3 3"></path>
                                            </svg>
                                        </div>
                                        <div className="ml-4">
                                            <p className="text-gray-800">Montag - Freitag: 08:00 - 16:00</p>
                                        </div>
                                    </li>

 */
