import { Link } from 'react-router-dom'

export const Imprint = () => {
    return (
        <>
            <main id='content' className='py-10 flex-grow flex items-center'>
                <div className='max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                    <div>
                        <h1 className='text-4xl sm:text-5xl lg:text-6xl text-gray-800 font-bold mb-2'>Impressum</h1>

                        <h1 className='text-2xl text-gray-800 font-medium mb-1 mt-4'>Seitenbetreiber</h1>

                        <div>Majo Holding GmbH</div>
                        <div>Judengasse 23</div>
                        <div>61169 Friedberg</div>
                        <div>Deutschland</div>
                        <div>E-Mail: info@majo-holding.de</div>

                        <h1 className='text-2xl text-gray-800 font-medium mb-1 mt-4'>Handelsregister</h1>

                        <div>Registergericht: Amtsgericht Friedberg</div>
                        <div>Registernummer: HRB 10273</div>

                        <h1 className='text-2xl text-gray-800 font-medium mb-1 mt-4'>Geschäftsführer</h1>

                        <div>Martin J. Müller</div>

                        <h1 className='text-2xl text-gray-800 font-medium mb-1 mt-4'>Inhaltlich Verantwortlich</h1>

                        <div>Martin J. Müller</div>
                        <div>(Anschrift wie oben)</div>

                        <h1 className='text-2xl text-gray-800 font-medium mb-1 mt-4'>Urheberrecht und Bildnachweise</h1>
                        <p>
                            Die Inhalte von majo-holding.de sind – soweit nicht abweichend angegeben – urheberrechtlich
                            geschützt.
                            <br />
                            Verwendete Fotografien sind mit Bildnachweisen gekennzeichnet oder im folgenden aufgeführt,
                            soweit sie nicht selbst angefertigt wurden.
                            <br />
                            Die Verwendung von Fotografien auf Drittseiten ist nur im Rahmen der jeweiligen Lizenz der
                            Urheber möglich.
                        </p>
                        <div>
                            <h2 className='text-lg text-gray-800 font-medium mb-1 mt-2'>Bildnachweise</h2>
                            <ul>
                                <li>Maklergespräch (Startseite) - Bild wurde mit Hilfe von KI erstellt.</li>
                                <li>Besprechungsbild (Karriere) - Bild wurde mit Hilfe von KI erstellt.</li>
                            </ul>
                        </div>
                        <h1 className='text-2xl text-gray-800 font-medium mb-1 mt-4'>Haftung für Inhalte</h1>
                        <p>
                            Wir bemühen uns die Inhalte unserer Seite aktuell zu halten. Trotz sorgfältiger Bearbeitung
                            bleibt eine Haftung ausgeschlossen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für
                            eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
                        </p>
                        <p>
                            Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte
                            oder gespeicherte fremde Informationen zu überwachen. Bei bekannt werden von
                            Rechtsverletzungen, werden wir diese Inhalte umgehend entfernen. Eine diesbezügliche Haftung
                            übernehmen wir erst ab dem Zeitpunkt der Kenntnis einer möglichen Rechtsverletzung.
                        </p>
                        <h1 className='text-2xl text-gray-800 font-medium mb-1 mt-4'>Haftung für Links</h1>
                        <p>
                            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
                            Einfluss haben. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
                            Betreiber der Seiten verantwortlich. Für die Inhalte und die Richtigkeit der Informationen
                            verlinkter Websites fremder Informationsanbieter wird keine Gewähr übernommen.
                        </p>
                        <p>
                            Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße ohne
                            Beanstandung überprüft. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                            umgehend entfernen.
                        </p>
                    </div>
                    <div className={'font-bold'}>Streitschlichtung fehlt noch.</div>
                    <div className=' mt-4'>
                        <div className=' inline rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20'>
                            <Link to='/' className='font-semibold text-[#826E42]'>
                                <span className='' aria-hidden='true'></span>Zurück zur Startseite.{' '}
                                <span aria-hidden='true'>&rarr;</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export function Component() {
    return <Imprint />
}
