import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import img_1 from '@/assets/h2.webp'

export const Career = () => {
    const showLink = true
    useEffect(() => {
        const script = document.createElement('script')

        script.src = 'https://www.scope-recruiting.de/js/scope-iframe/scopeIframe.min.js'
        script.async = true

        document.body.appendChild(script)
        script.addEventListener('load', () => {
            console.log('loaded')
            // @ts-ignore
            const scope = ScopeRecruiting.init({
                parentElementId: 'heyrecruit-frame',
                companyId: '2651',
                overviewPageSettings: {
                    standAloneSite: false,
                    show_description: false,
                    show_map: false,
                    filter: false
                }
            })
        })
        return () => {
            document.body.removeChild(script)
        }
    }, [])
    return (
        <>
            <div className='pt-10 flex-grow flex items-center'>
                <div className='max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                    <div className='text-center'>
                        <h1 className=' font-bold tracking-tight text-gray-900 text-4xl sm:text-5xl lg:text-6xl'>
                            Wir wachsen und suchen Verstärkung!
                            <br />
                            Sind <span className='text-[#826E42]'>Sie</span> bereit{' '}
                            <span className='text-[#826E42]'>für Neues</span>?
                        </h1>
                    </div>
                </div>
            </div>
            <main id='content' className='pt-6 pb-10 flex-grow flex flex-col items-center'>
                <div className='max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                    <div className='grid md:grid-cols-3  gap-4 md:gap-8 xl:gap-20 md:items-center'>
                        <div className=' items-center col-span-2'>
                            <p className='mt-3 text-lg text-gray-800'>
                                Schauen Sie sich unsere aktuellen Stellenangebote an und starten Sie jetzt Ihre Karriere
                                bei der Majo Holding GmbH.
                            </p>{' '}
                            <p className='mt-3 text-lg text-gray-800'>
                                Der Klick auf eine offene Stelle führt Sie auf die Stellenausschreibung von{' '}
                                <a className={'hover:underline'} href={'https://heyrecruit.de'}>
                                    heyrecruit.de
                                </a>
                                . Dort finden Sie die Details zur ausgeschriebenen Stelle und können sich schnell und
                                unkompliziert bewerben.
                            </p>
                        </div>

                        <div className='relative ms-4'>
                            <img className='w-full rounded-md' src={img_1} alt='Managing the future.' />
                            <div className='absolute inset-0 -z-[1] bg-gradient-to-tr from-[#826E42] via-white/0 to-white/0 size-full rounded-md mt-4 -mb-4 me-4 -ms-4 lg:mt-6 lg:-mb-6 lg:me-6 lg:-ms-6'></div>
                        </div>
                    </div>
                </div>
                <div className='w-full max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                    <section id='career' className='w-full  mx-auto grid auto-rows-fr grid-cols-1 mt-10 mb-2'>
                        <div id='heyrecruit-frame'></div>
                    </section>
                    <div className='max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                        <div className='relative isolate px-6 pt-14 lg:px-8'>
                            <div className='mx-auto max-w-2xl'>
                                {showLink ? (
                                    <div className='flex sm:mb-8 sm:flex sm:justify-center'>
                                        <div className='relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20'>
                                            <Link to='/' className='font-semibold text-[#826E42]'>
                                                <span className='absolute inset-0' aria-hidden='true'></span>Zurück zur
                                                Startseite. <span aria-hidden='true'>&rarr;</span>
                                            </Link>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export function Component() {
    return <Career />
}
