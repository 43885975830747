import logo from '@/assets/logo.png'
import img_1 from '@/assets/i2.webp'

export const Home = () => {
    return (
        <>
            <div className='pt-10 flex-grow flex items-center'>
                <div className='max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                    <div className='text-center'>
                        <img src={logo} className='max-w-64 mx-auto mb-5' alt='Majo Holding GmbH' />
                        <h1 className='block mb-6 font-bold text-gray-800 text-4xl sm:text-5xl lg:text-6xl lg:leading-tight'>
                            Willkommen bei der <span className='text-[#826E42]'>Majo Holding GmbH</span>.
                        </h1>
                    </div>
                </div>
            </div>
            <main id='content' className='py-10 flex-grow flex items-center'>
                <div className='max-w-[85rem]  mx-auto px-4 sm:px-6 lg:px-8'>
                    <div className='grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center'>
                        <div className=' items-center'>
                            <p className='mt-3 text-lg text-gray-800'>
                                Die <b>Majo Holding GmbH</b> mit Sitz in Friedberg bei Frankfurt vereint unter ihrem
                                Dach Hausverwaltungen, Handwerksbetriebe, Dienstleister und Ingenieurbüros. Wir haben
                                uns darauf spezialisiert für unsere Kunden ein Rund-um-Paket zum Thema Haus- und
                                WEG-Verwaltung zu bieten. Der Verwaltungsbestand der Majo Holding umfasst insgesamt rund
                                22.000 Wohnungen im mittel- und süddeutschen Bereich.
                            </p>
                            <p className='mt-3 text-lg text-gray-800'>
                                Die Unternehmensgruppe beschäftigt aktuell etwa 70 Mitarbeiter und plant mit den
                                Kernthemen Digitalisierung, Umwelt- und Klimaschutz weiter zu wachsen. Fundiert
                                aufgestellt steht die Majo Holding für Nachhaltigkeit und Qualität.
                            </p>
                        </div>

                        <div className='relative ms-4 mb-8 sm:mb-0'>
                            <img className='w-full rounded-md' src={img_1} alt='Managing the future.' />
                            <div className='absolute inset-0 -z-[1] bg-gradient-to-tr from-[#826E42] via-white/0 to-white/0 size-full rounded-md mt-4 -mb-4 me-4 -ms-4 lg:mt-6 lg:-mb-6 lg:me-6 lg:-ms-6'></div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export function Component() {
    return <Home />
}
